<template>
    <div>
        <b-overlay
            :show="isLoading"
            rounded
            opacity="0.6"
            spinner-variant="primary"
            spinner-mediam
        >
            <transition
                :name="$store.state.appConfig.layout.routerTransition"
                mode="out-in"
            >
                <b-row>
                    <b-col md="9">
                        <b-card no-body>
                            <b-card-body class="">
                                <p>نص إعلاني يظهر في أعلى المتجر، للإعلان عن تخفيضات أو عروض أو أي تنبيهات أخرى للعملاء.</p>
                                <b-form @submit.prevent="update">
                                    <!-- Content Text -->
                                    <b-row>
                                        <b-col cols="12">
                                            <b-form-group
                                                :label="$t('Content Text')"
                                            >
                                                <b-form-input v-model="announcement_text.top_titel" />
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col cols="6">
                                            <b-form-group
                                                :label="$t('Text Color')"
                                            >
                                                <b-form-input v-model="announcement_text.text_color" type="color" />
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="6">
                                            <b-form-group
                                                :label="$t('Background Color')"
                                            >
                                                <b-form-input v-model="announcement_text.background_color" type="color" />
                                            </b-form-group>
                                        </b-col>
                                    </b-row>

                                    <!-- Is Active -->
                                    <b-row>
                                        <b-col cols="12">
                                            <b-form-group
                                            :label="$t('Is Active ?')"
                                            label-for="v-is-active"
                                            >
                                                <b-form-checkbox
                                                    id="v-is-active"
                                                    class="custom-control-primary"
                                                    v-model="announcement_text.is_active"
                                                    name="is_active"
                                                    switch
                                                >
                                                    <span class="switch-icon-left">
                                                        <feather-icon icon="CheckIcon" />
                                                    </span>
                                                    <span class="switch-icon-right">
                                                        <feather-icon icon="XIcon" />
                                                    </span>
                                                </b-form-checkbox>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>

                                    <!-- submit -->
                                    <b-row>
                                        <b-col cols="12">
                                            <b-button
                                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                            type="submit"
                                            variant="primary"
                                            class="mr-1 mt-1"
                                            >
                                                <template v-if="!isLoadingSave">
                                                    {{$t('Save')}}
                                                </template>
                                                <template v-if="isLoadingSave">
                                                    {{$t('Saveing')}}...
                                                    <b-spinner small />
                                                </template>
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </b-form>
                            </b-card-body>
                        </b-card>
                    </b-col>
                </b-row>
            </transition>
        </b-overlay>
    </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/useJwt'
export default {
    directives: {
        Ripple,
    },
    mounted() {
        this.getResults()
    },
    data() {
        return {
            announcement_text: {},
            isLoading: true,
            isLoadingSave: false,
            coupons: []
        }
    },
    methods: {
        getResults() {
            useJwt.post('/store/config/get-by-id',{key: 'announcement_text'})
            .then((response) => {
                // console.log(response.data)
                if (response.data.data && Object.keys(response.data.data).length) {
                    this.announcement_text = response.data.data.announcement_text.announcement_text_active
                    this.announcement_text.is_active =  this.announcement_text.is_active ? true : false
                }
                this.isLoading = false
            })
            .catch(response => {
                console.log(response);
            });
        },
        update() {
            this.isLoadingSave = true;
            
            useJwt.post('/marketing-offers/announcement-text/save',this.announcement_text)
            .then((response) => {
                // console.log(response.data);
                this.isLoadingSave = false
                this.$root.showToast('success', 'save', '')
                // this.announcement_text = response.data.data;
            })
            .catch(error => {
                // console.log(response);
                this.isLoadingSave = false
                this.$root.showAlert('error', error.response.data.message)
            });
        },
    },
}
</script>

<style lang="sass" scoped>

</style>
